import React, { useState, useEffect, useContext } from 'react'
import { Link, useStaticQuery, graphql, navigate } from 'gatsby'
import Img from 'gatsby-image'
import MAINLAYOUT from '../components/layouts/mainLayout.jsx'
import SIDE_PANEL from '../components/side_panel/sidePanel.jsx'
import BOTTOM_MENU from '../components/bottom_menu/bottom_menu.jsx'
import Autosuggest from 'react-autosuggest'
import Swal from 'sweetalert2'
import '../assets/css/autosuggest.css'
import SearchBar from '../components/searchBar/searchBar.jsx'
import {
  GlobalStateContext,
  GlobalDispatchContext,
  GlobalCategoriesContext,
} from '../context/GlobalContextProvider'
import moment from 'moment'

const myStore = ({ location }) => {
  let state = useContext(GlobalStateContext)
  let dispatch = useContext(GlobalDispatchContext)
  const { fetchCategories, getAuthenticatedUser } = useContext(
    GlobalCategoriesContext
  )

  const { menuIcon, searchIcon, back } = useStaticQuery(graphql`
    query {
      menuIcon: file(relativePath: { eq: "img/button.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      searchIcon: file(relativePath: { eq: "img/search.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 20) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      back: file(relativePath: { eq: "img/back.png" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          fixed(width: 24) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  const [openSidePanel, setOpenSidePanel] = useState(false)
  const [userLocation, setUserLocation] = useState(void 0)
  const [suggestions, setSuggestions] = useState([])
  const [searchQuery, setSearchQuery] = useState('')
  const [categories, setCategories] = useState([])
  const [searchItem, setSearchItem] = useState('')
  const [retailerDetails, setRetalilerDetails] = useState(void 0)

  const getUserLocation = async () => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          let pos = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          }
          setUserLocation(pos)
        },
        error => {
          console.error('Error Code = ' + error.code + ' - ' + error.message)
        }
      )
    }
  }

  useEffect(() => {
    getAuthenticatedUser()
      .then(() => {
        state.gridView = true
        let { categories } = state
        if (categories) {
          console.log('categories found')
        } else {
          console.log('no categories - Preloading')
          fetchCategories()
            .then(response => {
              let { data } = response
              let tmp = []
              Object.keys(data).map((key, index) => {
                tmp.push(...data[key].children)
              })
              dispatch({ type: 'PRELOAD_CATEGORIES', payload: tmp })
            })
            .catch(err => console.log(err))
        }
        if (location.state && location.state.retailer) {
          let { searchResult } = state
          let retailData = Object.entries(searchResult.data)
          let retailer = retailData.filter(
            retailer => retailer[1].storeId.S === location.state.retailer
          )
          setRetalilerDetails(retailer)
        }
        getUserLocation()
        setCategories(categories)
      })
      .catch(() => {
        navigate('/')
      })
  }, [])

  const getSuggestions = value => {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? []
      : categories.filter(
          lang => lang.name.toLowerCase().slice(0, inputLength) === inputValue
        )
  }

  const getSuggestionValue = suggestion => {
    setSearchItem({ name: suggestion.name, itemId: suggestion.id })
    return suggestion.name
  }

  const renderSuggestion = suggestion => <div>{suggestion.name}</div>

  const _onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value))
  }

  const _onSuggestionsClearRequested = () => {
    setSuggestions([])
  }

  const _onChange = (event, { newValue }) => {
    setSearchQuery(newValue)
  }

  const inputProps = {
    placeholder: 'search for item',
    value: searchQuery,
    onChange: _onChange,
  }

  const _searchForItems = () => {
    if (searchQuery === '') {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'You must enter an Item',
      })
    } else {
      /** TODO
       *  - set search results to the globalContext searchResult state
       */
      console.log('searching for:')
      let searchPayload = {
        itemId: searchItem.itemId,
        item: searchItem.name,
        userLocation,
      }
      console.log(searchPayload)
    }
  }

  const _openSidePanel = () => {
    setOpenSidePanel(!openSidePanel)
  }

  const _sidePanelStateChangeHandler = newState => {
    setOpenSidePanel(newState.isOpen)
  }
  return (
    <MAINLAYOUT id="app">
      <BOTTOM_MENU />
      <SIDE_PANEL
        pageWrapId={'page-wrap'}
        outerContainerId={'app'}
        width={200}
        isOpen={openSidePanel}
        onStateChange={state => _sidePanelStateChangeHandler(state)}
        customBurgerIcon={false}
      />
      {retailerDetails ? (
        <>
          <section className="retailer-details bg-white bottom-8 w-full rounded-t-lg">
            <article
              className="bg-white rounded-lg shadow-sm p-4
        grid grid-cols-retailer gap-3
        capitalize text-darkGray
        text-sm sm:text-base sm:grid-cols-retailerDesktop
        "
            >
              <div
                className={`${
                  retailerDetails[0][1].products[0].quantity.S === 'MANY'
                    ? 'bg-lime'
                    : 'bg-orange'
                } w-5 h-5 rounded-full flex self-center`}
              ></div>
              <div>
                <div className="font-bold">{retailerDetails[0][1].name.S}</div>
                <div>
                  <div>{retailerDetails[0][1].address.S}</div>
                </div>
              </div>
              <div>
                <div className="capitalize sm:text-right">last update:</div>
                <div className="font-bold text-teal leading-4 sm:text-right">
                  {moment(retailerDetails[0][1].dateTime.S).format(
                    'MM/DD/YYYY'
                  )}
                </div>
              </div>
            </article>
          </section>
          <section
            className="bg-gray-100 relative h-screen w-full overflow-hidden"
            id="page-wrap"
          >
            {retailerDetails ? (
              <>
                <div className="container mx-auto p-5 ">
                  <Link
                    to="/search"
                    className="grid grid-cols-backGrid gap-2
              "
                  >
                    <Img fixed={back.childImageSharp.fixed} alt="crowdsupply" />
                    <div className="font-semibold">back to listing</div>
                  </Link>
                  <div className="font-bold capitalize mt-4">
                    available products
                  </div>
                  <section className="mt-2">
                    {retailerDetails[0][1].products.map((item, i) => (
                      <div
                        className="grid grid-cols-retailerItem gap-2 mb-2"
                        key={i}
                      >
                        <div
                          className={`${
                            item.quantity.S === 'MANY' ? 'bg-lime' : 'bg-orange'
                          } w-5 h-5 rounded-full flex self-center`}
                        ></div>
                        <div className="text-2xl">{item.name.S}</div>
                        <div style={{ width: '20rem' }}>
                          <img
                            src={`https://cs-image-upload.s3.amazonaws.com/${item.image.S}`}
                            alt="Westockup"
                          />
                        </div>
                      </div>
                    ))}
                  </section>
                </div>
              </>
            ) : (
              ''
            )}
          </section>
        </>
      ) : null}
    </MAINLAYOUT>
  )
}

export default myStore
